import React from "react"
import Layout from "../components/layout"

import { SharedStateProvider } from "../components/SharedState/PageSharedState"
import SEO from "../components/seo"
import { Link, graphql } from "gatsby"
import { getPostObj } from "../utils/utils"

function createHtml(html) {
  return { __html: html }
}
var showdown = require("showdown")
const converter = new showdown.Converter()

const ThankYouPage = ({ pageContext, location, data }) => {
  var post
  var language
  let postObj = getPostObj(
    pageContext,
    data.allUniquePagesJson.nodes[0],
    data.allSpanishUniquePagesJson.nodes[0]
  )
  post = postObj.post
  language = pageContext.language

  return (
    <SharedStateProvider>
      <Layout language={language}>
        <SEO
          lang={language}
          title={post.metaTitle}
          description={post.metaDescription}
          pathname={location.pathname}
          schemaPage={post.schemaPage ? post.schemaPage : null}
        />
        <div className="joshua-tree-content">
          <div className="columns">
            <div className="column">
              <div className="columns top-section text-section is-mobile color-back">
                <div className="column is-4"></div>
                <div className="column is-16">
                  <div
                    dangerouslySetInnerHTML={createHtml(
                      converter.makeHtml(post.body)
                    )}
                  ></div>
                </div>
                <div className="column is-4"></div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </SharedStateProvider>
  )
}

export const pageQuery = graphql`
  query thankYouPage($title: String!) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        body
        metaTitle
        metaDescription
      }
    }
    allSpanishUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        body
        metaTitle
        metaDescription
      }
    }
  }
`

export default ThankYouPage
